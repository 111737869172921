import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Avatar",
        "componentName": "Avatar"
      }}>{`<Avatar src="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200" />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Avatar" mdxType="Props" />
    <h2 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}</h2>
    <p><inlineCode parentName="p">{`default: medium`}</inlineCode></p>
    <p>{`All avatars are available in sets of four different sizes, where the `}<strong parentName="p">{`default`}</strong>{`
size is `}<strong parentName="p">{`Medium`}</strong>{`.`}</p>
    <ul>
      <li parentName="ul">{`Small`}</li>
      <li parentName="ul">{`Medium`}</li>
      <li parentName="ul">{`Large`}</li>
      <li parentName="ul">{`xLarge`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Avatar size="small" src="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200" />
<Avatar size="medium" src="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200" />
<Avatar size="large" src="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200" />
<Avatar size="xLarge" src="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200" />
`}</code></pre>
    <h2 {...{
      "id": "circular",
      "style": {
        "position": "relative"
      }
    }}>{`Circular`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`Make the avatar Circular shaped.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Avatar circular={false} src="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200" />
<Avatar circular={true} src="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200" />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      